/*!****************************************************************************************************************************************************************************!*\
  !*** css ../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../node_modules/less-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!./less/naba/gge-naba.less ***!
  \****************************************************************************************************************************************************************************/
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/*!********************************************************************************************************************************************************************************!*\
  !*** css ../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../node_modules/less-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!./less/naba/gge-naba.less (1) ***!
  \********************************************************************************************************************************************************************************/
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
/*!********************************************************************************************************************************************************************************!*\
  !*** css ../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../node_modules/less-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!./less/naba/gge-naba.less (2) ***!
  \********************************************************************************************************************************************************************************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
main.main--errorPage {
  height: 100vh;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
main.main--errorPage .container--errorPage {
  width: 312px;
  margin: auto;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
main.main--errorPage .container--errorPage .box {
  width: 100%;
  height: 189px;
  font-size: 160px;
  font-weight: 100;
  text-align: center;
  color: #ffffff;
}
main.main--errorPage .container--errorPage .message {
  height: 52px;
  padding: 0 10px;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.85px;
  text-align: center;
  color: #ffffff;
}
main.main--errorPage .container--errorPage .cta {
  margin-top: 61px;
}
main.main--errorPage .container--errorPage .cta a.gohome {
  width: 90%;
  margin: 0 auto;
  display: block;
  background-color: #df002a;
  text-decoration: none;
  color: #ffffff;
  line-height: 64px;
  text-transform: uppercase;
  text-align: center;
}
main.main--errorPage.background--404 {
  background-image: url("/fe-web/img/naba/404-background.jpg");
  background-size: cover;
  background-position: top center;
  background-color: black;
}
main.main--errorPage.background--404 a.btn {
  text-decoration: none;
}
main.main--errorPage.background--404 .btn {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 0.375rem 1.3rem;
  line-height: 1.5;
  border-radius: 0px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #000000;
  color: #ffffff;
  border: 2px solid transparent;
}
main.main--errorPage.background--404 .btn--large {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
}
main.main--errorPage.background--404 .btn:hover {
  background-color: #ffffff;
  color: #000000;
  border-color: #000000;
}
main.main--errorPage.background--404 .btn.no-filter {
  filter: none !important;
}
main.main--errorPage.background--404 .btn--invert {
  background-color: #ffffff;
  color: #000000;
  border-color: #ffffff;
}
main.main--errorPage.background--404 .btn--invert:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #ffffff;
}
main.main--errorPage.background--500 {
  background-image: url("/fe-web/img/naba/404-background.jpg");
  background-size: cover;
  background-position: top center;
  background-color: black;
}
.workSansLight {
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.workSansRegular {
  font-family: 'Work Sans', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.workSansMedium {
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.workSansSemibold {
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.workSansBold {
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.bebasNeueBold {
  font-family: 'Bebas Neue', cursive;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.KD-card-wrapper,
.KD-card {
  position: relative;
}
.KD-card-anchor {
  position: absolute;
  display: block;
  top: -200px;
  height: 0;
  width: 0;
  left: 0;
}
[component="AiCoursesComponent"] .c-header-gallery__ctawrapper {
  padding-top: 25px;
}
#contact-tool {
  position: fixed;
  bottom: 15px;
  right: 25px;
}
#contact-tool .link {
  cursor: pointer;
  height: 46px;
  width: 46px;
  border: 4px solid black;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  background: white;
  color: black;
}
#contact-tool .link:hover {
  color: white;
  background: black;
  border-color: white;
}
@media (max-width: 767px) {
  #contact-tool .link.no-mobile {
    display: none;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  #contact-tool .link.no-mobile {
    display: none;
  }
}
#contact-tool .link .icon {
  max-width: 30px;
  max-height: 30px;
}
#contact-tool .link .icon.phone svg {
  max-width: 26px;
  max-height: 26px;
}
select option.disabled {
  display: none;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.form-section .form-input__wrap.form-input__select.disabled {
  pointer-events: none;
}
.form-section .form-input__wrap.form-input__select.disabled:after {
  display: none;
}
h1 {
  font-width: normal;
}
@media screen and (min-width: 1024px) {
  .c-header-gallery__slide__image.avoidOverlap {
    grid-column: 7 / 13;
  }
}
@media screen and (min-width: 1600px) {
  .c-header-gallery__slide__image.avoidOverlap {
    grid-column: 5 / 13;
  }
}
.header-fixed .header__navigation__ctas .admission-tooltip {
  margin-top: -15px;
  top: 90px !important;
}
.header-fixed .header__navigation__ctas .admission-tooltip:after {
  border-width: 0 15px 15px 15px;
  border-color: transparent transparent #FFFFFF transparent;
  top: -15px;
}
.header__navigation__ctas .admission-tooltip,
.mobile-main-menu__bottom-content .admission-tooltip {
  background: #FFF;
  display: block;
  font-size: 11px;
  margin-left: -84px;
  margin-top: -53px;
  padding: 10px;
  position: absolute;
}
.header__navigation__ctas .admission-tooltip:after,
.mobile-main-menu__bottom-content .admission-tooltip:after {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 15px 15px 0 15px;
  border-color: #FFFFFF transparent transparent transparent;
  transform: rotate(0deg);
  position: absolute;
  bottom: -14px;
  left: 50%;
  content: "";
  display: block;
  margin-left: -15px;
}
@media (max-width: 1339px) {
  .header__navigation__ctas .admission-tooltip,
  .mobile-main-menu__bottom-content .admission-tooltip {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .header__navigation__ctas .admission-tooltip,
  .mobile-main-menu__bottom-content .admission-tooltip {
    display: none !important;
  }
}
.mobile-main-menu__bottom-content .admission-tooltip {
  display: none !important;
}
@media (max-width: 767px) {
  .mobile-main-menu__bottom-content .admission-tooltip {
    display: block !important;
    padding: 4px 10px 6px;
    margin: 15px 0 10px;
    text-align: center;
    position: relative;
    font-size: 10px;
    width: 100%;
  }
  .mobile-main-menu__bottom-content .admission-tooltip a {
    display: block;
  }
}
body .nabasquare[data-no-animation] img {
  opacity: 1;
}
#iti-1__country-listbox {
  z-index: 999;
}
.iti__country-list {
  z-index: 999;
}
#projectFilters .page-filters-horizontal__container {
  justify-content: flex-end;
}
.footer-upper-socials__link {
  cursor: pointer;
  position: relative;
}
.footer-upper-socials__link .img-baloon {
  display: none;
  position: absolute;
  right: 100%;
  margin-top: -60px;
  margin-right: 15px;
  top: -10%;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: #222;
}
.footer-upper-socials__link .img-baloon:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent #222;
  position: absolute;
  top: 74%;
  left: 100%;
  margin-left: 0;
  margin-top: -6px;
  content: "";
  display: block;
}
.footer-upper-socials__link .img-baloon .img-baloon-social {
  display: block;
  width: 100%;
  height: 100%;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  image-rendering: -webkit-optimize-contrast;
}
.footer-upper-socials__link[data-social="wechat"] .img-baloon-social {
  background-image: url("/fe-web/naba/dist/images/icons/socials/popup/wechat.png");
}
.footer-upper-socials__link[data-social="douyin"] .img-baloon-social {
  background-image: url("/fe-web/naba/dist/images/icons/socials/popup/douyin.png");
}
.footer-upper-socials__link[data-social="bilibili"] .img-baloon-social {
  background-image: url("/fe-web/naba/dist/images/icons/socials/popup/bilibili.png");
}
.footer-upper-socials__link[data-social="weibo"] .img-baloon-social {
  background-image: url("/fe-web/naba/dist/images/icons/socials/popup/weibo.png");
}
.footer-upper-socials__link.visible .img-baloon {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 1024px) {
  .project-single .sustainability-goals-container.only-three-col {
    grid-template-columns: repeat(3, 1fr);
  }
  .project-single .sustainability-goals-container.only-two-col {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1340px) {
  .grid__course-info.kd-three-columns {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .footer-lower .footer-lower__content-address,
  .footer-lower .footer-lower__content-vat,
  .footer-lower .footer-lower__content-links {
    display: block;
  }
  .footer-lower .footer-lower__content-address > a,
  .footer-lower .footer-lower__content-vat > a,
  .footer-lower .footer-lower__content-links > a {
    margin-right: 10px;
    text-decoration: underline;
    color: #000;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
  body.form [data-test-id="ChatWidgetButton"],
  body.form [data-test-id="ChatWidgetMobileButton"] {
    display: none !important;
  }
  .page-header__title {
    margin: 1.125rem !important;
  }
  .footer-lower .footer-lower__content-address,
  .footer-lower .footer-lower__content-vat,
  .footer-lower .footer-lower__content-links {
    display: block;
  }
  .footer-lower .footer-lower__content-address > a,
  .footer-lower .footer-lower__content-vat > a,
  .footer-lower .footer-lower__content-links > a {
    margin-right: 10px;
    text-decoration: underline;
    color: #000;
  }
  .footer-upper-socials__link .img-baloon {
    left: -130%;
    margin-top: -60px;
    bottom: 150%;
    top: auto;
  }
  .footer-upper-socials__link .img-baloon:after {
    bottom: -8px;
    left: 50%;
    top: auto;
  }
}
section.KD-card-wrapper.c-header-gallery {
  padding-top: 0;
}
.grecaptcha-badge {
  visibility: hidden;
}
@media (max-width: 767px) {
  .landing-page .header__logo {
    flex: 0 0 60px;
  }
}
.landing-page .header__logo .logo-icon.mobile {
  display: none;
}
@media (max-width: 767px) {
  .landing-page .header__logo .logo-icon {
    display: none;
  }
  .landing-page .header__logo .logo-icon.mobile {
    display: block;
  }
}
@media (max-width: 767px) {
  .landing-page .header__topbar {
    display: none;
  }
}
.landing-page .inline-landing {
  position: relative;
}
.landing-page .inline-landing #landing-form-anchor {
  position: absolute;
  top: -100px;
  height: 1px;
  width: 10px;
  left: 0;
}
.landing-page .inline-landing .custom-modal-inner {
  margin: 70px auto 10px;
  max-width: 60%;
}
@media (max-width: 767px) {
  .landing-page .inline-landing .custom-modal-inner {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .landing-page .inline-landing .modal-landing__footer .btn--stroked-dark {
    box-sizing: border-box;
    margin: 15px auto 10px;
    padding: 15px 0;
    display: block;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .landing-page .heading-section__cta-wrap {
    flex-direction: column;
  }
  .landing-page .heading-section__cta-wrap a {
    margin-top: 30px;
  }
}
.landing-page .modal-landing__footer .btn--stroked-dark.loading {
  background: transparent;
  position: relative;
  border: none;
  pointer-events: none;
}
.modal-landing__inputs .w-adapt {
  width: auto !important;
  max-width: 100%;
  flex-grow: 1;
}
.modal-landing__footer {
  align-items: flex-start;
}
@media (max-width: 767px) {
  .modal-landing__footer {
    align-items: center;
  }
}
.modal-landing__footer .btn--stroked-dark {
  margin-top: 25px;
}

